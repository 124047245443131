export const PARTS_LIST = 'PARTS_LIST';
export const TASKS_LIST = 'TASKS_LIST';
export const RIGS_LIST = 'RIGS_LIST';
export const PERMIT_REPORT_LIST = 'PERMIT_REPORT_LIST';
export const INDIVIDUAL_PERMIT_REPORT_LIST = 'INDIVIDUAL_PERMIT_REPORT_LIST';
export const LOAD_LIST_REPORT = 'LOAD_LIST_REPORT';
export const PUSHER_REPORT_LIST = 'PUSHER_REPORT_LIST';
export const RIG_UPDATE_REPORT_LIST = 'RIG_UPDATE_REPORT_LIST';
export const CUSTOMER_REPORT_LIST = 'CUSTOMER_REPORT_LIST';
export const LOS_RIG_REPORT_LIST = 'LOS_RIG_REPORT_LIST';
export const RIGS_PICS_LIST = 'RIGS_PICS_LIST';
export const PUSHER_PICS_LIST = 'PUSHER_PICS_LIST';
export const ASSETS_PICS_LIST = 'ASSETS_PICS_LIST';
export const SAFETY_REPORT_LIST = 'SAFETY_REPORT_LIST';
export const RESET_RIG_DATA = 'RESET_RIG_DATA';

export const partsList = (data) => ({
	type: PARTS_LIST,
	data
});

export const tasksList = (data) => ({
	type: TASKS_LIST,
	data
});

export const rigsList = (data) => ({
	type: RIGS_LIST,
	data
});

export const permitReportList = (data) => ({
	type: PERMIT_REPORT_LIST,
	data
});

export const individualPermitReportList = (data) => ({
	type: INDIVIDUAL_PERMIT_REPORT_LIST,
	data
});

export const loadListReport = (data) => ({
	type: LOAD_LIST_REPORT,
	data
});

export const pusherReportList = (data) => ({
	type: PUSHER_REPORT_LIST,
	data
});

export const rigUpdateReportList = (data) => ({
	type: RIG_UPDATE_REPORT_LIST,
	data
});

export const customerReportList = (data) => ({
	type: CUSTOMER_REPORT_LIST,
	data
});

export const losRigReportList = (data) => ({
	type: LOS_RIG_REPORT_LIST,
	data
});

export const safetyReportList = (data) => ({
	type: SAFETY_REPORT_LIST,
	data
});

export const rigsPicsList = (data) => ({
	type: RIGS_PICS_LIST,
	data
});

export const pusherPicsList = (data) => ({
	type: PUSHER_PICS_LIST,
	data
});

export const assetsPicsList = (data) => ({
	type: ASSETS_PICS_LIST,
	data
});

export const resetRigData = () => ({
	type: RESET_RIG_DATA
});