import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Col, Menu, Row, Table, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { BsFilter } from 'react-icons/bs';
import '../jsa.css';
import loader from '../../../assets/imgs/loader.gif';
import { checkWritePermissions } from '../../../utils/native';
import * as rigActions from '../../../redux/actions/rigActions';

const { TabPane } = Tabs;
class JSAReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'losSafety'),
			safetyReports: [],
			archivedReports: [],
			selectedTab: 1,
			searchInput: '',
			isLoading: false,
			columns: [
				{
					title: 'Date',
					dataIndex: 'date',
					key: 'date',
					render: (record) => (record && moment(record).format('MMM DD, YYYY'))
				},
				{
					title: 'Reason',
					dataIndex: 'reason',
					key: 'reason'
				},
				{
					title: 'Title',
					dataIndex: 'title',
					key: 'title'
				},
				{
					title: 'All',
					dataIndex: 'allSafe',
					key: 'allSafe',
				},
				{
					title: 'Shift',
					dataIndex: 'shift',
					key: 'shift'
				},
				{
					title: 'At',
					dataIndex: 'behaviorObserved',
					key: 'behaviorObserved'
				},
				{
					title: 'Hazard',
					dataIndex: 'hazardObserved',
					key: 'hazardObserved'
				},
				{
					title: 'Proper',
					dataIndex: 'ppeObserved',
					key: 'ppeObserved'
				},
				{
					title: 'Environment',
					dataIndex: 'environmentImpacted',
					key: 'environmentImpacted'
				},
				{
					title: 'Weather',
					dataIndex: 'weatherAffect',
					key: 'weatherAffect'
				},
				{
					title: 'Caught',
					dataIndex: 'caught',
					key: 'caught'
				},
				{
					title: 'Corrective',
					dataIndex: 'correctiveActions',
					key: 'correctiveActions'
				},
				{
					title: 'h2sLevel',
					dataIndex: 'h2sLevel',
					key: 'h2sLevel'
				},
				{
					title: 'Responsible',
					dataIndex: 'responsible',
					key: 'responsible'
				},
			],
			filteredList: ['date', 'reason', 'title', 'allSafe', 'shift', 'behaviorObserved', 'hazardObserved', 'ppeObserved', 'environmentImpacted', 
				'weatherAffect', 'caught', 'correctiveActions', 'h2sLevel', 'responsible'],
			filteredCols: [],
			filtered: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'losSafety') });
		}
	}

	componentDidMount() {
		const { safetyReportList } = this.props.rig;
		if (safetyReportList.length > 0) {
			this.getSafetyReports(safetyReportList);
		} else {
			this.setState({ isLoading: true });
			const reportsRef = firebase.firestore().collection('jobsafetyanalysis').orderBy('createdAt', 'desc');
			reportsRef.get().then((querySnapshot) => {
				const reportList = querySnapshot.docs.map((doc) => {return {...doc.data(), '$key': doc.id};});
				this.props.dispatchSafetyReportList(reportList);
				this.getSafetyReports(reportList);
			});
		}
	}

	getSafetyReports(list) {
		const temp = [];
		const archived = [];
		list.forEach((data) => {
			if (data.archive !== true) {
				temp.push(data);
			}
			if (data.archive === true) {
				archived.push(data);
			}
		});
		this.setState({ safetyReports: temp, archivedReports: archived, isLoading: false });
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key) });
	};

	userHeader = () => {
		const { searchInput, filtered } = this.state;
		return (
			<>
				<Row className="search-rig-header">
					<Col span={4}>
						<div className="filter-table-area">
							<button className="site-btn rounded">
								<BsFilter size={40} onClick={() => this.onFilterClick()} />
							</button>
							{ filtered && <div className="filter-column-box">{this.columnLists()}</div> }
						</div>
					</Col>
					<Col span={20} className="search-btn-part">
						<Search
							placeholder="Search Reason, Title, All Safe, h2sLevel"
							onSearch={value => this.jsaReportSearch(value)}
							style={{ width: '100%' }}
							className="rigSearch"
							allowClear={true}
							onChange={data => this.jsaReportSearch(data.target.value)}
							value={searchInput}
						/>
						<button className="site-btn export-btn">Export</button>
					</Col>
				</Row>
			</>
		);
	}

	onFilterClick = () => {
		const { filtered, filteredList } = this.state;
		this.setState({ filtered: !filtered });
		if (filtered === true) {
			this.handleFilterChange(filteredList);
		}
	}

	handleMenuChange = (value) => {
		const { filteredList } = this.state;
		if (filteredList.includes(value.key) === true) {
			if (filteredList.length > 3) {
				let index = filteredList.indexOf(value.key);
				filteredList.splice(index, 1);
			} else {
				alert('Atleast 3 columns will remain selected for table');
			}
		} else {
			filteredList.push(value.key);
		}
		this.setState({ filteredList });
		this.handleFilterChange(filteredList);
	}

	handleFilterChange = (value) => {
		const { columns } = this.state;
		let userCols = [];
		columns.forEach((col) => {
			value.forEach((item) => {
				if (col.key === item) {
					userCols.push(col);
				}
			});
		});
		this.setState({ filteredCols: userCols });
		this.handleColsChanges(userCols, true);
	}

	handleColsChanges(definedCols, filter) {
		if (filter === false) {
			this.setState({ userColumns: definedCols });
		} else {
			this.setState({ filteredCols: definedCols });				
		}
	}

	jsaReportSearch(value) {
		this.setState({ searchInput: value });

	}

	filterData = (item) => {
		const string = this.state.searchInput.toLowerCase();
		return item.reason?.toLowerCase().match(string) || item.title?.toLowerCase().match(string) || item.allSafe?.toLowerCase().match(string) || item.h2sLevel?.toLowerCase().match(string);
	};

	jsaLists(safetyLists) {
		const { columns, filteredCols } = this.state;
		return (
			<div>
				<Row>
					<Col span={24}>
						<Table columns={filteredCols.length === 0 ? columns : filteredCols} dataSource={safetyLists.filter(this.filterData)} rowKey={record => record.$key} scroll={{ x: true }} />
					</Col>
				</Row>
			</div>
		);
	}

	columnLists = () => {
		const { filteredList } = this.state;
		return (
			<Menu selectedKeys={filteredList} multiple={true} onClick={(value) => this.handleMenuChange(value)}>
				<Menu.Item key="date" onClick={(value) => this.setState({ 'date': value.key })}>Date</Menu.Item>
				<Menu.Item key="reason" onClick={(value) => this.setState({ 'reason': value.key })}>Reason</Menu.Item>
				<Menu.Item key="title" onClick={(value) => this.setState({ 'title': value.key })}>Title</Menu.Item>
				<Menu.Item key="allSafe" onClick={(value) => this.setState({ 'allSafe': value.key })}>All</Menu.Item>
				<Menu.Item key="shift" onClick={(value) => this.setState({ 'shift': value.key })}>Shift</Menu.Item>
				<Menu.Item key="behaviorObserved" onClick={(value) => this.setState({ 'behaviorObserved': value.key })}>At</Menu.Item>
				<Menu.Item key="hazardObserved" onClick={(value) => this.setState({ 'hazardObserved': value.key })}>Hazard</Menu.Item>
				<Menu.Item key="ppeObserved" onClick={(value) => this.setState({ 'ppeObserved': value.key })}>Proper</Menu.Item>
				<Menu.Item key="environmentImpacted" onClick={(value) => this.setState({ 'environmentImpacted': value.key })}>Environment</Menu.Item>
				<Menu.Item key="weatherAffect" onClick={(value) => this.setState({ 'weatherAffect': value.key })}>Weather</Menu.Item>
				<Menu.Item key="caught" onClick={(value) => this.setState({ 'caught': value.key })}>Caught</Menu.Item>
				<Menu.Item key="correctiveActions" onClick={(value) => this.setState({ 'correctiveActions': value.key })}>Corrective</Menu.Item>
				<Menu.Item key="h2sLevel" onClick={(value) => this.setState({ 'h2sLevel': value.key })}>h2sLevel</Menu.Item>
				<Menu.Item key="responsible" onClick={(value) => this.setState({ 'responsible': value.key })}>Responsible</Menu.Item>
			</Menu>
		);
	}

	render() {
		const { safetyReports, archivedReports, selectedTab, isLoading } = this.state;
		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<>
				<div className="card-tab-body">
					<div className="custom-tabs no-mt">
						<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
							<TabPane tab={`Active (${safetyReports.length})`} key="1">
								{this.userHeader()}
								{selectedTab === 1 && this.jsaLists(safetyReports)}
							</TabPane>
							<TabPane tab={`Archived (${archivedReports.length})`} key="2">
								{this.userHeader()}
								{selectedTab === 2 && this.jsaLists(archivedReports)}
							</TabPane>
						</Tabs>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

const mapDispatchToProps = {
	dispatchSafetyReportList: (data) => rigActions.safetyReportList(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JSAReport));