import React, { Component } from 'react';
import firebase from '../../services/firebase';
import loader from '../../assets/imgs/loader.gif';
import { Table, Tabs, Input, Row, Col, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import '../users/users.css';
import './permission.css';
import { isEmpty } from '../../utils/activity-logs';
import moment from 'moment';
import { connect } from 'react-redux';

const { TabPane } = Tabs;
const { Search } = Input;
class Permissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rig: '',
			isLoading: false,
			losEmployees: [],
			checkboxLoading: false,
			rigs: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			admins: [],
			selectedTab: 1,
			searchInput: '',
			validateTrigger: false,
			selectedUID: '',
			userColumns: [
				{
					title: 'First Name',
					dataIndex: 'firstName',
					key: 'firstName',
					align: 'left',
					width: 100,
					fixed: 'left'
				},
				{
					title: 'Last Name',
					dataIndex: 'lastName',
					key: 'lastName',
					align: 'left',
					width: 100,
					fixed: 'left'
				},
				{
					title: 'Group',
					dataIndex: 'group',
					key: 'group',
					align: 'left',
					render: (group) => group && group.map((groups) => groups).join(),
					width: 150,
					fixed: 'left'
				},
				{
					title: 'Rigs',
					dataIndex: 'rigs',
					key: 'rigs',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'rigs'),
				},
				{
					title: 'Users',
					dataIndex: 'users',
					key: 'users',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'users'),
				},
				{
					title: 'Pusher',
					dataIndex: 'pusher',
					key: 'pusher',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'pusher'),
				},
				{
					title: 'Reports',
					dataIndex: 'reports',
					key: 'reports',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'reports'),
				},
				{
					title: 'Safety Form / Report',
					dataIndex: 'losSafety',
					key: 'losSafety',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'losSafety'),
				},
				{
					title: 'Smart Groups',
					dataIndex: 'smartGroups',
					key: 'smartGroups',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'smartGroups'),
				},
				{
					title: 'Rig Pics',
					dataIndex: 'rigPics',
					key: 'rigPics',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'rigPics'),
				},
				{
					title: 'Assets',
					dataIndex: 'assets',
					key: 'assets',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'assets'),
				},
				{
					title: 'Individual Loads',
					dataIndex: 'individualLoads',
					key: 'individualLoads',
					align: 'left',
					render: (_, record) => record.modulePermissions.modules && this.renderModule(record, 'individualLoads'),
				},
			],
			shopList: [
				{ key: 'admin', value: 'Admin-Receptionist' }, { key: 'asst_manager', value: 'Asst. Manager' }, { key: 'contractor', value: 'Contractor' },
				{ key: 'crane', value: 'Crane Operator' }, { key: 'dispatch', value: 'Dispatch' }, { key: 'field', value: 'Field' },
				{ key: 'forklift', value: 'Forklift (DOT)' }, { key: 'contract', value: 'HSE - Advisor (Contract)' }, { key: 'trainer', value: 'HSE - Advisor (Field Trainer)' },
				{ key: 'coordinator', value: 'HSE - Coordinator' }, { key: 'director', value: 'HSE - Director' }, { key: 'ofc_manager', value: 'OFC Manager' },
				{ key: 'office', value: 'Office' }, { key: 'shop', value: 'Shop' },
			]
		};
	}

	componentDidMount() {	
		this.setState({ isLoading: true });
		this.getLOSEmployees();
	}

	getLOSEmployees() {
		const { usersList } = this.props.auth;
		const admins = usersList.filter((item) => item.userType === 'admin' && item.archive !== true);
		const losUsers = usersList.filter((item) => item.userType === 'LOS' && item.archive !== true);
		this.setState({ losEmployees: losUsers, admins: admins, isLoading: false });
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key), searchInput: '' });
	};

	userHeader = () => {
		const { searchInput } = this.state;
		return (
			<>
				<Row className="search-rig-header">
					<Col className="search-btn-part" span={4}>
					</Col>
					<Col className="search-btn-part" span={20}>
						<Search
							placeholder="Search..."
							onSearch={value => this.rigSearch(value)}
							style={{ width: '100%' }}
							className="rigSearch"
							allowClear={true}
							onChange={data => this.rigSearch(data.target.value)}
							value={searchInput}
						/>
					</Col>
				</Row>
			</>
		);
	}

	filterData = (item) => {
		const string = this.state.searchInput.toLowerCase();
		return item.firstName.toLowerCase().match(string) || item.lastName.toLowerCase().match(string) || item.email.toLowerCase().match(string);
	};

	userLists(users) {
		let { userColumns } = this.state;
		return (
			<div style={{ marginTop: 20 }}>
				<Row>
					<Col span={24}>
						<Table className="shadow-table" columns={userColumns} dataSource={users.filter(this.filterData)} rowKey={record => record.$key} scroll={{ x: true }} />
					</Col>
				</Row>
			</div>
		);
	}

	rigSearch(value) {
		this.setState({ searchInput: value });
	}

	renderModule(record, moduleType) {
		const { checkboxLoading } = this.state;
		return (
			record.modulePermissions.modules.map((module, index) => {
				return (
					module.type === moduleType && (
						<Row className="fixedtable-checkbox-row" key={index}>
							<Col className='colContent'>
								{checkboxLoading === false &&
									<>
										<Checkbox checked={module.read} onChange={() => this.updateReadPermission(record, index, 'read', !module.read, module.write, module.delete)} /> View
									</>
								}
							</Col>
							{moduleType !== 'individualLoads' && <Col className='colContent'><Checkbox checked={module.write} onChange={() => this.updateWritePermission(record, index, 'write', !module.write)} /> Edit</Col>}
							<Col className='colContent'><Checkbox checked={module.delete} onChange={() => this.updateDeletePermission(record, index, 'delete', !module.delete)} /> Delete</Col>
						</Row>
					));
			})
		);
	}

	async updateReadPermission(record, index, permissionType, value, writeValue, deleteValue) {
		if (permissionType === 'read' && writeValue === false && (isEmpty(deleteValue) || deleteValue === false)) {
			this.setState({ checkboxLoading: true });
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.updateBy = localStorage.getItem('user');
			record.modulePermissions.updateAt = moment().valueOf();
			this.setState({ checkboxLoading: false });

			const db = firebase.firestore();
			await db.doc(`users/${record.$key}`).update(record)
				// .then(() => this.setState({ isLoading: false }))
				.catch(() => { });
		}
	}

	async updateWritePermission(record, index, permissionType, value) {
		this.setState({ checkboxLoading: true });
		if (permissionType === 'write' && value === true) {
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.modules[index]['read'] = value;
		} else {
			record.modulePermissions.modules[index][permissionType] = value;
		}
		record.modulePermissions.updateBy = localStorage.getItem('user');
		record.modulePermissions.updateAt = moment().valueOf();
		this.setState({ checkboxLoading: false });

		const db = firebase.firestore();
		await db.doc(`users/${record.$key}`).update(record)
			// .then(() => this.setState({ isLoading: false }))
			.catch(() => { });
	}

	async updateDeletePermission(record, index, permissionType, value) {
		this.setState({ checkboxLoading: true });
		if (permissionType === 'delete' && value === true) {
			record.modulePermissions.modules[index][permissionType] = value;
			record.modulePermissions.modules[index]['read'] = value;
		} else {
			record.modulePermissions.modules[index][permissionType] = value;
		}
		record.modulePermissions.updateBy = localStorage.getItem('user');
		record.modulePermissions.updateAt = moment().valueOf();
		this.setState({ checkboxLoading: false });

		const db = firebase.firestore();
		await db.doc(`users/${record.$key}`).update(record)
			.catch(() => { });
	}

	render() {
		const { isLoading, losEmployees, admins, selectedTab } = this.state;
		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card" defaultActiveKey={selectedTab.toString()} onChange={this.onChange} centered>
						<TabPane tab={`Mobile Users (${losEmployees.length})`} key="1">
							{this.userHeader()}
							{selectedTab === 1 && this.userLists(losEmployees)}
						</TabPane>
						<TabPane tab={`Admins (${admins.length})`} key="2">
							{this.userHeader()}
							{selectedTab === 2 && this.userLists(admins)}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Permissions));
