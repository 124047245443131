import {
	PARTS_LIST,
	TASKS_LIST,
	RIGS_LIST,
	RESET_RIG_DATA,
	PERMIT_REPORT_LIST,
	INDIVIDUAL_PERMIT_REPORT_LIST,
	LOAD_LIST_REPORT,
	PUSHER_REPORT_LIST,
	RIG_UPDATE_REPORT_LIST,
	CUSTOMER_REPORT_LIST,
	LOS_RIG_REPORT_LIST,
	RIGS_PICS_LIST,
	PUSHER_PICS_LIST,
	ASSETS_PICS_LIST,
	SAFETY_REPORT_LIST,
} from '../actions/rigActions';


let initialState = {
	partsList: [],
	tasksList: [],
	rigsList: [],
	permitReportList: [],
	individualPermitReportList: [],
	loadListReport: [],
	pusherReportList: [],
	rigUpdateReportList: [],
	customerReportList: [],
	losRigReportList: [],
	rigsPicsList: [],
	pusherPicsList: [],
	assetsPicsList: [],
	safetyReportList: [],
};

const rigReducer = (state = initialState, action) => {
	switch (action.type) {
		case PARTS_LIST:
			return { ...state, partsList: action.data };
		case TASKS_LIST:
			return { ...state, tasksList: action.data };
		case RIGS_LIST:
			return { ...state, rigsList: action.data };
		case PERMIT_REPORT_LIST:
			return { ...state, permitReportList: action.data };
		case INDIVIDUAL_PERMIT_REPORT_LIST:
			return{ ...state, individualPermitReportList: action.data };
		case LOAD_LIST_REPORT:
			return { ...state, loadListReport: action.data };
		case PUSHER_REPORT_LIST:
			return { ...state, pusherReportList: action.data };
		case RIG_UPDATE_REPORT_LIST:
			return { ...state, rigUpdateReportList: action.data };
		case CUSTOMER_REPORT_LIST:
			return { ...state, customerReportList: action.data };
		case LOS_RIG_REPORT_LIST:
			return { ...state, losRigReportList: action.data };
		case SAFETY_REPORT_LIST:
			return { ...state, safetyReportList: action.data };
		case RIGS_PICS_LIST:
			return { ...state, rigsPicsList: action.data };
		case PUSHER_PICS_LIST:
			return { ...state, pusherPicsList: action.data };
		case ASSETS_PICS_LIST:
			return { ...state, assetsPicsList: action.data };
		case RESET_RIG_DATA:
			return { ...initialState };
		default:
			return state;
	}
};

export default rigReducer;
